import React from 'react';
import Head from '../components/head';
import Form from '../components/form';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

const SubmitHomework = ({ location }) => {
    return (
        <>
            <Head 
                pageTitle="Submit Assignment" 
                location={location}
                description="Here's where we'll submit links to our assignments in the program. These submissions are received and evaluated by the program instructor"
            />
            <Breadcrumb 
                location={location} 
                crumbLabel={"Submit Assignment"}
            />
            <main>
                <Form />
            </main>
        </>
    );
};


export default SubmitHomework;
